import PropTypes from "prop-types";

import IconButton from "@mui/joy/IconButton";
import Tooltip from "@mui/joy/Tooltip";

import {SIDEBAR_BUTTON_HEIGHT} from "./constants";


const SidebarButton = ({
    sx,
    currentTab,
    icon,
    name,
    onClick,
}) => {
    const routeName = name.toLowerCase().replace(" ", "-");

    return (
        <IconButton
            data-name={routeName}
            sx={{
                ...sx,
                height: SIDEBAR_BUTTON_HEIGHT,
            }}
            variant={
                currentTab === routeName ?
                    "solid" :
                    "outlined"
            }
            onClick={onClick}
        >
            <Tooltip title={name}>
                {icon}
            </Tooltip>
        </IconButton>
    );
};

SidebarButton.propTypes = {
    sx: PropTypes.object,

    currentTab: PropTypes.string,
    icon: PropTypes.element,
    name: PropTypes.string,

    onClick: PropTypes.func,
};

export default SidebarButton;
