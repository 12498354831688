import axios, {CanceledError} from "axios";


/**
 * Retrieves user profile data from server and sets it.
 *
 * @param {object} requestOptions
 * @param {AbortController} requestOptions.abortController
 * @param {string} requestOptions.authToken
 * @param {object} handlers
 * @param {function} handlers.setter
 * @param {function} handlers.notifier
 * @returns {Promise<void>}
 *
 * @throws {CanceledError} if the API request is canceled via abortController
 */
const getAndSetUserProfile = async ({
    abortController,
    authToken,
}, {
    setter,
    notifier,
}) => {
    try {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/user`, {
            headers: {Authorization: `Bearer ${authToken}`},
            signal: abortController.signal,
        });
        const {data} = resp;

        setter({firstName: data.email});
    } catch (e) {
        if (false === e instanceof CanceledError) {
            notifier("error", e.code, e.message);
        }
    }
};

export {getAndSetUserProfile};
