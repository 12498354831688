import PropTypes from "prop-types";

import IconButton from "@mui/joy/IconButton";
import Snackbar from "@mui/joy/Snackbar";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

import CloseIcon from "@mui/icons-material/Close";


const messageTypeToColor = {
    error: "danger",
    info: "neutral",
    success: "success",
    warning: "warning",
};

const NOTIFICATION_ESTIMATED_HEIGHT_PX = 100;

const Notification = ({
    id,
    index,
    message,
    onClose,
    title,
    type,
}) => {
    const color = messageTypeToColor[type];

    return (
        <Snackbar
            autoHideDuration={5000}
            color={color}
            open={true}
            size={"lg"}
            variant={"outlined"}
            anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
            }}
            endDecorator={
                <IconButton
                    color={color}
                    size={"sm"}
                    variant={"plain"}
                    onClick={() => onClose(id)}
                >
                    <CloseIcon/>
                </IconButton>
            }
            sx={{
                bottom:
                    `calc(var(--Snackbar-inset) + ${NOTIFICATION_ESTIMATED_HEIGHT_PX * index}px)`,
            }}
            onClose={(event, reason) => {
                if ("timeout" === reason) {
                    onClose(id);
                }
            }}
        >
            <Stack spacing={0.5}>
                <Typography level={"title-md"}>
                    {title}
                </Typography>
                {message}
            </Stack>
        </Snackbar>
    );
};

Notification.propTypes = {
    id: PropTypes.string,
    index: PropTypes.number,
    message: PropTypes.string,
    onClose: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
};
export default Notification;
