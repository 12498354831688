import {StrictMode} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";

import App from "./App";
import Auth0ProviderWithNavigate from "./Auth0ProviderWithNavigate";
import reportWebVitals from "./reportWebVitals";
import {NotificationProvider} from "./ui/components/Notification/NotificationContext";

import "./index.css";


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <StrictMode>
        <BrowserRouter>
            <NotificationProvider>
                <Auth0ProviderWithNavigate>
                    <App/>
                </Auth0ProviderWithNavigate>
            </NotificationProvider>
        </BrowserRouter>
    </StrictMode>
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
