import PropTypes from "prop-types";
import {
    createContext, useCallback, useState,
} from "react";
import {v4 as uuidv4} from "uuid";

import Notification from "./Notification";


const NotificationContext = createContext([]);

const NotificationProvider = ({children}) => {
    const [notifications, setNotifications] = useState([]);

    const postNotification = useCallback((type, title, message) => {
        setNotifications((prevNotifications) => [
            ...prevNotifications,
            {
                id: uuidv4(),
                message,
                title,
                type,
            },
        ]);
    }, []);

    const removeNotification = useCallback((id) => {
        setNotifications((prevNotifications) => prevNotifications.filter((p) => (p.id !== id)));
    }, []);

    return (
        <NotificationContext.Provider value={[postNotification]}>
            {children}
            {
                notifications.map((n, i) => (
                    <Notification
                        index={i}
                        key={n.id}
                        onClose={removeNotification}
                        {...n}/>
                ))
            }
        </NotificationContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.node,
};

export {NotificationContext, NotificationProvider};
