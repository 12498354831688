import PropTypes from "prop-types";

import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Divider from "@mui/joy/Divider";
import Sheet from "@mui/joy/Sheet";

import DashboardRounded from "@mui/icons-material/DashboardRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";

import YScopeLogoBlue from "../icons/YScopeLogoBlue.png";
import ColorSchemeToggle from "./ColorSchemeToggle";
import {SIDEBAR_WIDTH} from "./constants";
import SidebarButton from "./SidebarButton";


const SideBarLogoContainer = () => (
    <Box
        sx={{
            alignItems: "center",
            display: "flex",
        }}
    >
        <img
            alt={""}
            src={YScopeLogoBlue}
            width={"100%"}/>
    </Box>
);

const SideBarTopButtonsContainer = ({
    currentTab,
    onClick,
}) => (
    <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: "1",
            gap: "1em",
            overflowX: "hidden",
            overflowY: "hidden",
        }}
    >
        <SidebarButton
            currentTab={currentTab}
            icon={<HomeRoundedIcon/>}
            name={"Home"}
            onClick={onClick}/>

        <SidebarButton
            currentTab={currentTab}
            icon={<DashboardRounded/>}
            name={"Dashboard"}
            onClick={onClick}/>

        <SidebarButton
            currentTab={currentTab}
            icon={<QueryStatsRoundedIcon/>}
            name={"Stats"}
            onClick={onClick}/>
    </Box>
);

SideBarTopButtonsContainer.propTypes = {
    currentTab: PropTypes.string,
    onClick: PropTypes.func,
};

const SideBarBottomButtonsContainer = ({
    currentTab,
    onClick,
}) => (
    <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
        }}
    >
        <ColorSchemeToggle/>
    </Box>
);

SideBarBottomButtonsContainer.propTypes = {
    currentTab: PropTypes.string,
    onClick: PropTypes.func,
};

const SideBarProfileButtonContainer = () => (
    <Box
        sx={{
            alignItems: "center",
            display: "flex",
            gap: 1,
            justifyContent: "center",
        }}
    >
        <Avatar
            variant={"outlined"}
            src={"https://www.google.com/images/branding/googlelogo/2x/" +
                "googlelogo_color_272x92dp.png"}/>
    </Box>
);


const Sidebar = ({
    onTabChange,
    tab,
}) => {
    const handleTabButtonClick = (ev) => {
        const tabName = ev.currentTarget.dataset.name;

        onTabChange(tabName);
    };

    return (
        <Sheet
            sx={{
                borderColor: "divider",
                borderRightStyle: "solid",
                borderRightWidth: "1px",
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                height: "100dvh",
                padding: "1em",
                position: "fixed",
                width: SIDEBAR_WIDTH,
                zIndex: "10000",
            }}
        >
            <SideBarLogoContainer/>
            <Divider/>

            <SideBarTopButtonsContainer
                currentTab={tab}
                onClick={handleTabButtonClick}/>

            <SideBarBottomButtonsContainer
                currentTab={tab}
                onClick={handleTabButtonClick}/>

            <Divider/>
            <SideBarProfileButtonContainer/>
        </Sheet>
    );
};

Sidebar.propTypes = {
    onTabChange: PropTypes.func,
    tab: PropTypes.string,
};

export default Sidebar;
