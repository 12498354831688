import {
    useEffect, useRef,
} from "react";

import {useAuth0} from "@auth0/auth0-react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

import LoginIcon from "@mui/icons-material/Login";


const Home = () => {
    const {
        loginWithRedirect,
    } = useAuth0();

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Stack
                    gap={4}
                    sx={{mb: 2}}
                >
                    <Stack gap={1}>
                        <Typography
                            component={"h1"}
                            level={"h3"}
                        >
                            {"Sign in"}
                        </Typography>
                        <Typography level={"body-sm"}>
                            {"New to YScope Cloud? "}
                            <Link
                                level={"title-sm"}
                                onClick={() => loginWithRedirect({
                                    appState: {
                                        returnTo: "/dashboard",
                                    },
                                    authorizationParams: {
                                        screen_hint: "signup",
                                    },
                                })}
                            >
                                {"Sign up!"}
                            </Link>
                        </Typography>
                    </Stack>
                    <Button
                        color={"neutral"}
                        fullWidth={true}
                        startDecorator={<LoginIcon/>}
                        variant={"soft"}
                        onClick={() => loginWithRedirect({
                            appState: {
                                returnTo: "/dashboard",
                            },
                        })}
                    >
                        {"Continue with Auth0"}
                    </Button>
                </Stack>

                <Box
                    component={"footer"}
                    sx={{py: 3}}
                >
                    <Typography
                        level={"body-xs"}
                        textAlign={"center"}
                    >
                        {"© YScope Inc. "}
                        {new Date().getFullYear()}
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default Home;
