import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

import {Auth0Provider} from "@auth0/auth0-react";


/**
 * Wraps the provided component with Auth0Provider from Auth0 SDK.
 *
 * @param {React.ReactNode} children
 * @returns {JSX.Element|null}
 */
const Auth0ProviderWithNavigate = ({children}) => {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const audience = process.env.REACT_APP_AUTH0_API_AUDIENCE;

    const handleRedirect = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            clientId={clientId}
            domain={domain}
            authorizationParams={{
                audience,
                redirect_uri: redirectUri,
            }}
            onRedirectCallback={handleRedirect}
        >
            {children}
        </Auth0Provider>
    );
};

Auth0ProviderWithNavigate.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Auth0ProviderWithNavigate;
