import {
    Route, Routes,
} from "react-router-dom";

import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";

import {SIDEBAR_WIDTH} from "../components/Sidebar/constants";
import Dashboard from "./Dashboard/Dashboard";
import Home from "./Home/Home";


const PageContainer = () => (
    <Box
        sx={{
            height: "100%",
            left: SIDEBAR_WIDTH,
            overflowY: "auto",
            position: "fixed",
            top: 0,
            width: `calc(100vw - ${SIDEBAR_WIDTH})`,
        }}
    >
        <Sheet
            sx={{
                minHeight: "100vh",
                p: 3,
            }}
        >
            <Routes>
                <Route
                    element={<Home/>}
                    path={"/"}/>
                <Route
                    element={<Home/>}
                    path={"/home"}/>
                <Route
                    element={<Dashboard/>}
                    path={"/dashboard"}/>
                <Route
                    element={<Dashboard/>}
                    path={"/stats"}/>
            </Routes>
        </Sheet>
    </Box>
);

export default PageContainer;
