import PropTypes from "prop-types";
import {useContext} from "react";

import {useAuth0} from "@auth0/auth0-react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";
import Tooltip from "@mui/joy/Tooltip";
import Typography from "@mui/joy/Typography";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import StopRoundedIcon from "@mui/icons-material/StopRounded";

import {stopDeployment} from "../../../api/deployment";
import {NotificationContext} from "../../components/Notification/NotificationContext";
import {capitalizeFirstLetter} from "../../utils";
import {
    CARD_HEIGHT, CARD_WIDTH,
} from "./constants";


const DeploymentLaunchButton = ({
    children,
    disabled,
}) => (
    <IconButton
        color={"success"}
        disabled={disabled}
        size={"lg"}
        variant={"solid"}
        sx={{
            borderRadius: "50%",
            bottom: 0,
            position: "absolute",
            right: "1rem",
            transform: "translateY(50%)",
            zIndex: 2,
        }}
    >
        {children}
    </IconButton>
);

DeploymentLaunchButton.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
};

const DeploymentThumbnail = () => (
    <AspectRatio ratio={"2"}>
        <div style={{flexDirection: "column"}}>
            <p>
                {"usage graph"}
            </p>
            <p>
                {"some graph 123"}
            </p>
        </div>
    </AspectRatio>
);

const DeploymentCard = ({
    deploymentId,
    deploymentName,
    error,
    publicUrl,
    region,
    status,
}) => {
    const {getAccessTokenSilently} = useAuth0();
    const [postNotification] = useContext(NotificationContext);

    const handleStopDeployment = async () => {
        const controller = new AbortController();
        try {
            const authToken = await getAccessTokenSilently();
            await stopDeployment({
                abortController: controller,
                authToken,
                data: {deploymentId},
            }, {
                notifier: postNotification,
                onDone: () => {
                    window.location.reload();
                },
            });
        } catch (e) {
            postNotification("error", e.name, e.message);
            console.error(e.message);
        }
    };

    const handleDeleteDeployment = async () => {
        // TODO: revisit
        console.log("do nothing for now");

        // Const controller = new AbortController();
        // Try {
        //     Const authToken = await getAccessTokenSilently();
        //     Await deleteDeployment({
        //         AbortController: controller,
        //         AuthToken,
        //         Data: deploymentId,
        //     }, {
        //         Notifier: postNotification,
        //         OnDone: () => {
        //             Window.location.reload();
        //         },
        //     });
        // } catch (e) {
        //     PostNotification("error", e.name, e.message);
        //     Console.error(e.message);
        // }
    };

    const isDeploymentRunnable = ("RUNNING_STATE" === status);

    return (
        <Card
            variant={"outlined"}
            sx={{
                height: CARD_HEIGHT,
                width: CARD_WIDTH,
            }}
        >
            <CardOverflow>
                <DeploymentThumbnail/>
                <DeploymentLaunchButton
                    disabled={false === isDeploymentRunnable}
                >
                    <Link
                        href={publicUrl}
                        overlay={true}
                        target={"_blank"}/>
                    <PlayArrowRoundedIcon/>
                </DeploymentLaunchButton>
            </CardOverflow>
            <CardContent>
                <Typography level={"title"}>
                    <Link
                        disabled={false === isDeploymentRunnable}
                        href={publicUrl}
                        overlay={true}
                        target={"_blank"}
                        underline={"always"}
                    >
                        {deploymentName}
                    </Link>
                </Typography>
                <Typography level={"body"}>
                    <Link
                        disabled={false === isDeploymentRunnable}
                        href={publicUrl}
                        target={"_blank"}
                    >
                        {region}
                    </Link>
                </Typography>
            </CardContent>
            <CardOverflow variant={"soft"}>
                <Divider inset={"context"}/>
                <CardContent
                    orientation={"horizontal"}
                    sx={{alignItems: "center"}}
                >
                    <Typography
                        level={"body-xs"}
                        sx={{flexGrow: 1}}
                    >
                        {capitalizeFirstLetter(status)}
                    </Typography>

                    <Divider orientation={"vertical"}/>

                    <Stack
                        alignContent={"center"}
                        direction={"row"}
                        spacing={1}
                    >
                        {("" !== error) &&
                            <Tooltip
                                color={"danger"}
                                title={error}
                            >
                                <IconButton
                                    color={"danger"}
                                    size={"sm"}
                                    sx={{cursor: "default"}}
                                >
                                    <ErrorRoundedIcon
                                        color={"danger"}
                                        size={"sm"}/>
                                </IconButton>
                            </Tooltip>}

                        {true === [
                            "CREATE_REQUESTED_STATE",
                            "CREATING_STATE",
                            "CREATE_FAILED_STATE",
                            "RUNNING_STATE",
                        ].includes(status) ?
                            <Tooltip title={"Stop Deployment"}>
                                <IconButton
                                    size={"sm"}
                                    onClick={handleStopDeployment}
                                >
                                    <StopRoundedIcon/>
                                </IconButton>
                            </Tooltip> :
                            <Tooltip
                                title={"DELETED_STATE" !== status ?
                                    "Waiting for deployment deletion to complete..." :
                                    "Delete Deployment - Behaviour to be decided"}
                            >
                                {/* FIXME: remove the above text once we decide the behaviour*/}
                                <span>
                                    <IconButton
                                        loading={"DELETED_STATE" !== status}
                                        size={"sm"}
                                        slotProps={{
                                            /* FIXME: remove this slotProps if the offset bug is fixed in JoyUI */
                                            loadingIndicator: {sx: {top: "10px"}},
                                        }}
                                        onClick={handleDeleteDeployment}
                                    >
                                        <CloseRoundedIcon/>
                                    </IconButton>
                                </span>
                            </Tooltip>}
                    </Stack>
                </CardContent>
            </CardOverflow>
        </Card>
    );
};

DeploymentCard.propTypes = {
    deploymentId: PropTypes.string,
    deploymentName: PropTypes.string,
    error: PropTypes.string,
    publicUrl: PropTypes.string,
    region: PropTypes.string,
    status: PropTypes.string,
};

const NewDeploymentCard = ({
    disabled,
    onClick,
}) => {
    return (
        <Card
            variant={"outlined"}
            sx={{
                height: CARD_HEIGHT,
                padding: 0,
                width: CARD_WIDTH,
            }}
        >
            <CardContent>
                <IconButton
                    disabled={disabled}
                    sx={{
                        height: "100%",
                        width: "100%",
                    }}
                    onClick={onClick}
                >
                    <AddRoundedIcon sx={{fontSize: "100px"}}/>
                </IconButton>
            </CardContent>
        </Card>
    );
};

NewDeploymentCard.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

const LoadingDeploymentCard = () => {
    return (
        <Card
            variant={"outlined"}
            sx={{
                height: CARD_HEIGHT,
                width: CARD_WIDTH,
            }}
        >
            <CardOverflow>
                <AspectRatio ratio={"2"}>
                    <Skeleton/>
                </AspectRatio>
                <DeploymentLaunchButton
                    disabled={true}
                >
                    <Skeleton variant={"circular"}/>
                </DeploymentLaunchButton>
            </CardOverflow>
            <CardContent>
                <Typography level={"title"}>
                    <Skeleton>
                        {"Dummy Instance Name"}
                    </Skeleton>
                </Typography>
                <Typography level={"body"}>
                    <Skeleton>
                        {"Dummy Location"}
                    </Skeleton>
                </Typography>
            </CardContent>
            <CardOverflow variant={"soft"}>
                <Divider inset={"context"}/>
                <CardContent orientation={"horizontal"}>
                    <Typography level={"body-xs"}>
                        <Skeleton>
                            {"Pending"}
                        </Skeleton>
                    </Typography>
                    <Divider orientation={"vertical"}/>
                    <Typography level={"body-xs"}>
                        <Skeleton>
                            {"none"}
                        </Skeleton>
                    </Typography>
                </CardContent>
            </CardOverflow>
        </Card>
    );
};


export {DeploymentCard, LoadingDeploymentCard, NewDeploymentCard};
