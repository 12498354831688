import axios, {CanceledError} from "axios";


/**
 * Retrieves deployment information from server and sets it.
 *
 * @param {object} requestOptions
 * @param {AbortController} requestOptions.abortController
 * @param {string} requestOptions.authToken
 * @param {object} handlers
 * @param {function} handlers.setter
 * @param {function} handlers.notifier
 * @returns {Promise<void>}
 *
 * @throws {CanceledError} if the API request is canceled via abortController
 */
const getAndSetDeployments = async ({
    abortController,
    authToken,
}, {
    setter,
    notifier,
}) => {
    try {
        const resp = await axios.get(`${process.env.REACT_APP_API_URL}/deployments`, {
            headers: {Authorization: `Bearer ${authToken}`},
            signal: abortController.signal,
        });

        setter(resp.data);
    } catch (e) {
        if (false === e instanceof CanceledError) {
            notifier("error", e.code, e.message);
        }
    }
};

/**
 * FIXME: revisit
 * Adds new deployment for demo purpose.
 *
 * @param {object} requestOptions
 * @param {AbortController} requestOptions.abortController
 * @param {string} requestOptions.authToken
 * @param {object} requestOptions.data Deployment details
 * @param {object} handlers
 * @param {function} handlers.onDone
 * @param {function} handlers.notifier
 * @returns {Promise<void>}
 *
 * @throws {CanceledError} if the API request is canceled via abortController
 */
const addNewDeployment = async ({
    abortController,
    authToken,
    data,
}, {
    onDone,
    notifier,
}) => {
    try {
        const resp = await axios.post(`${process.env.REACT_APP_API_URL}/deployments`, data, {
            headers: {Authorization: `Bearer ${authToken}`},
            signal: abortController.signal,
        });

        onDone();
    } catch (e) {
        if (false === e instanceof CanceledError) {
            notifier("error", e.code, e.message);
        }
    }
};

/**
 * FIXME: revisit
 * Deletes deployment.
 *
 * @param {object} requestOptions
 * @param {AbortController} requestOptions.abortController
 * @param {string} requestOptions.authToken
 * @param {object} requestOptions.data Deployment ID
 * @param {object} handlers
 * @param {function} handlers.onDone
 * @param {function} handlers.notifier
 * @returns {Promise<void>}
 *
 * @throws {CanceledError} if the API request is canceled via abortController
 */
const deleteDeployment = async ({
    abortController,
    authToken,
    data,
}, {
    onDone,
    notifier,
}) => {
    try {
        const resp = await axios.delete(`${process.env.REACT_APP_API_URL}/deployment/${data}`, {
            headers: {Authorization: `Bearer ${authToken}`},
            signal: abortController.signal,
        });

        onDone();
    } catch (e) {
        if (false === e instanceof CanceledError) {
            notifier("error", e.code, e.message);
        }
    }
};

/**
 * FIXME: revisit
 * Stops deployment.
 *
 * @param {object} requestOptions
 * @param {AbortController} requestOptions.abortController
 * @param {string} requestOptions.authToken
 * @param {object} requestOptions.data Deployment ID
 * @param {object} handlers
 * @param {function} handlers.onDone
 * @param {function} handlers.notifier
 * @returns {Promise<void>}
 *
 * @throws {CanceledError} if the API request is canceled via abortController
 */
const stopDeployment = async ({
    abortController,
    authToken,
    data,
}, {
    onDone,
    notifier,
}) => {
    try {
        const resp = await axios.put(
            `${process.env.REACT_APP_API_URL}/deployment`,
            data,
            {
                headers: {Authorization: `Bearer ${authToken}`},
                signal: abortController.signal,
            }
        );

        onDone();
    } catch (e) {
        if (false === e instanceof CanceledError) {
            notifier("error", e.code, e.message);
        }
    }
};

export {addNewDeployment, deleteDeployment, getAndSetDeployments, stopDeployment};
