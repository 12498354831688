import PropTypes from "prop-types";

import IconButton from "@mui/joy/IconButton";
import {useColorScheme} from "@mui/joy/styles";

import DarkModeRoundedIcon from "@mui/icons-material/DarkModeRounded";
import LightModeIcon from "@mui/icons-material/LightMode";

import {SIDEBAR_BUTTON_HEIGHT} from "./constants";


const ColorSchemeToggle = ({
    sx,
    onClick,
}) => {
    const {
        mode,
        setMode,
    } = useColorScheme();

    return (
        <IconButton
            color={"neutral"}
            id={"toggle-mode"}
            variant={"outlined"}
            sx={{
                ...sx,
                height: SIDEBAR_BUTTON_HEIGHT,
            }}
            onClick={(event) => {
                if ("light" === mode) {
                    setMode("dark");
                } else {
                    setMode("light");
                }
                onClick?.(event);
            }}
        >
            {("dark" === mode) ?
                <DarkModeRoundedIcon/> :
                <LightModeIcon/>}
        </IconButton>
    );
};

ColorSchemeToggle.propTypes = {
    sx: PropTypes.object,

    onClick: PropTypes.func,
};

export default ColorSchemeToggle;
