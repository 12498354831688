import {
    useContext, useState,
} from "react";
import {Transition} from "react-transition-group";

import {useAuth0} from "@auth0/auth0-react";
import Button from "@mui/joy/Button";
import DialogActions from "@mui/joy/DialogActions";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import Stack from "@mui/joy/Stack";

import {addNewDeployment} from "../../../api/deployment";
import {NotificationContext} from "../../components/Notification/NotificationContext";


const DEPLOYMENT_FIELD_TYPES = Object.freeze({
    INTEGER: "INTEGER",
    SELECT: "SELECT",
    STRING: "STRING",
});

const isValueInSelectRange = (value, range) => {
    if (false === (value in range)) {
        return "Not a valid option.";
    }

    return null;
};
const isValueInIntegerRange = (value, range) => {
    if (false === Number.isInteger(value)) {
        return "Not an integer.";
    }

    if (value < range.min) {
        return `Below the minimum of ${range.min}.`;
    }

    if (value > range.max) {
        return `Exceeds the maximum of ${range.max}.`;
    }

    return null;
};

/* eslint-disable sort-keys */
const DEPLOYMENT_SCHEMA = [
    {
        key: "cloud_provider",
        label: "Cloud Provider",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.SELECT,
        range: {
            aws: "Amazon Web Services (AWS)",
            azure: "Microsoft Azure",
        },
        defaultValue: "aws",
        helperText: "Cloud Provider",
        validator: isValueInSelectRange,
    },
    {
        key: "region",
        label: "Region",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.SELECT,
        range: {
            "us-west-1": "us-west-1",
        },
        defaultValue: "us-west-1",
        helperText: "Region",
        validator: isValueInSelectRange,
    },
    {
        key: "owner_id",
        label: "Owner Id",
        fromUser: false,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "",
        helperText: "Debug (to be removed) - Leave it empty to set your user id",
        validator: () => (null),
    },
    {
        key: "cloud_provider_account_id",
        label: "Cloud Provider Account Id",
        fromUser: false,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "568954113123",
        helperText: "Debug (to be removed) - Fixed",
        validator: () => (null),
    },
    {
        key: "deployment_name",
        label: "Deployment Name",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "Some New Deployment",
        helperText: "Name to identify your deployment (should it be unique?)",
        validator: () => (null),
    },
    {
        key: "status",
        label: "Status",

        // FIXME: should be false
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.SELECT,
        range: {
            CREATE_REQUESTED_STATE: "Create Requested",
            CREATING_STATE: "Creating",
            CREATE_FAILED_STATE: "Create Failed",
            RUNNING_STATE: "Running",
            DELETE_REQUESTED_STATE: "Delete Requested",
            DELETING_STATE: "Deleting",
            DELETE_FAILED_STATE: "Delete Failed",
            DELETED_STATE: "Deleted - should not be enumerated",
        },
        defaultValue: "CREATE_REQUESTED_STATE",
        helperText: "Debug (to be removed) - status of the deployment",
        validator: isValueInSelectRange,
    },
    {
        key: "deployment_type",
        label: "Deployment Type",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.SELECT,
        range: {
            clp: "CLP | Text Optimized",
            "clp-s": "CLP-S | JSON Optimized",
        },
        defaultValue: "clp",
        helperText: "Compressing logs in opposite types can negatively impact performance",
        validator: () => (null),
    },
    {
        key: "customer_iam",
        label: "Customer IAM",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "arn:aws:iam::568954113123:role/HaiqiIAMTestCustomerRole",
        helperText: "AWS IAM for ingestion",
        validator: () => (null),
    },
    {
        key: "deployment_version",
        label: "Deployment Version",
        fromUser: false,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "0.0.0",
        helperText: "Debug (to be removed) - deployment version",
        validator: () => (null),
    },
    {
        key: "deployment_version",
        label: "Deployment Version",
        fromUser: false,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "0.0.0",
        helperText: "Debug (to be removed) - deployment version",
        validator: () => (null),
    },
    {
        key: "dynamic_version",
        label: "Dynamic Version",
        fromUser: false,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "0.1.0",
        helperText: "Debug (to be removed) - Dynamic version",
        validator: () => (null),
    },
    {
        key: "static_version",
        label: "Static Version",
        fromUser: false,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "0.1.0",
        helperText: "Debug (to be removed) - Static version",
        validator: () => (null),
    },
    {
        key: "controller_instance_type",
        label: "Controller Instance Type",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.SELECT,
        range: {
            "t4g.micro": "$998 - t4g.micro - 2 vCPU + 1 GiB",
            "t4g.small": "$8866 - t4g.small - 2 vCPU + 2 GiB",
            "t4g.medium": "$10086 - t4g.medium - 2 vCPU + 4 GiB",
        },
        defaultValue: "t4g.medium",
        helperText: "Deployment controller_instance_type",
        validator: isValueInSelectRange,
    },
    {
        key: "worker_instance_type",
        label: "Worker Instance Type",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.SELECT,
        range: {
            "t4g.micro": "$998 - t4g.micro - 2 vCPU + 1 GiB",
            "t4g.small": "$8866 - t4g.small - 2 vCPU + 2 GiB",
            "t4g.medium": "$10086 - t4g.medium - 2 vCPU + 4 GiB",
        },
        defaultValue: "t4g.small",
        helperText: "Deployment worker_instance_type",
        validator: isValueInSelectRange,
    },
    {
        key: "num_spot_search_workers",
        label: "num_spot_search_workers",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.INTEGER,
        range: {
            min: 1,
            max: 4,
        },
        defaultValue: 1,
        helperText: "Deployment num_spot_search_workers",
        validator: isValueInIntegerRange,
    },
    {
        key: "num_on_demand_search_workers",
        label: "num_on_demand_search_workers",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.INTEGER,
        range: {
            min: 1,
            max: 4,
        },
        defaultValue: 1,
        helperText: "Deployment num_on_demand_search_workers",
        validator: isValueInIntegerRange,
    },
    {
        key: "num_spot_compression_workers",
        label: "num_spot_compression_workers",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.INTEGER,
        range: {
            min: 1,
            max: 4,
        },
        defaultValue: 1,
        helperText: "Deployment num_spot_compression_workers",
        validator: isValueInIntegerRange,
    },
    {
        key: "num_on_demand_compression_workers",
        label: "num_on_demand_compression_workers",
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.INTEGER,
        range: {
            min: 1,
            max: 4,
        },
        defaultValue: 1,
        helperText: "Deployment num_on_demand_compression_workers",
        validator: isValueInIntegerRange,
    },
    {
        key: "error",
        label: "Error Message",

        // FIXME: should be false
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "",
        helperText: "Debug (to be removed) - Deployment error",
        validator: () => (null),
    },
    {
        key: "public_url",
        label: "public_url",

        // FIXME: should be false
        fromUser: true,
        type: DEPLOYMENT_FIELD_TYPES.STRING,
        range: null,
        defaultValue: "",
        helperText: "Debug (to be removed) - Deployment Public URL",
        validator: () => (null),
    },
];
/* eslint-enable sort-keys */

const INITIAL_DEPLOYMENT = {};
DEPLOYMENT_SCHEMA.forEach((s) => (INITIAL_DEPLOYMENT[s.key] = s.defaultValue));


const InputElement = ({
    field,
    onFieldChange,
    onFieldErrorChange,
}) => {
    const [value, setValue] = useState(field.defaultValue);
    const [valueError, setValueError] = useState(null);

    if (false === field.fromUser) {
        return <></>;
    }

    const handleInputFocus = (ev) => {
        ev.target.select();
    };

    const handleInputChange = (newValue) => {
        if (DEPLOYMENT_FIELD_TYPES.INTEGER === field.type) {
            if ("" === newValue) {
                newValue = "";
            } else {
                newValue = parseInt(newValue, 10);
            }
        }

        const error = field.validator(newValue, field.range);
        setValueError(error);
        onFieldErrorChange(field.key, error);
        if (null !== error) {
            console.error(`Field "${field.label}" error: ${error}`);
        }

        setValue(newValue);
        onFieldChange(field.key, newValue);
    };

    let coreElement = <></>;
    switch (field.type) {
        case DEPLOYMENT_FIELD_TYPES.INTEGER:
            coreElement = (
                <Input
                    type={"number"}
                    value={value}
                    onChange={(ev) => handleInputChange(ev.target.value)}
                    onFocus={handleInputFocus}/>
            );
            break;
        case DEPLOYMENT_FIELD_TYPES.SELECT:
            coreElement = (
                <Select
                    value={value}
                    onChange={(_, newValue) => handleInputChange(newValue)}
                >
                    {Object.entries(field.range).map(([k, v]) => (
                        <Option
                            key={k}
                            value={k}
                        >
                            {v}
                        </Option>
                    ))}
                </Select>
            );
            break;
        case DEPLOYMENT_FIELD_TYPES.STRING:
            coreElement = (
                <Input
                    value={value}
                    onChange={(ev) => handleInputChange(ev.target.value)}
                    onFocus={handleInputFocus}/>
            );
            break;
        default:
            console.error(`Unexpected InputElement type: ${field.type}`);
            break;
    }

    return (
        <FormControl
            key={field.key}
            color={(null === valueError) ?
                "neutral" :
                "danger"}
        >
            <FormLabel>
                {field.label}
            </FormLabel>
            {coreElement}
            <FormHelperText>
                {(null === valueError) ?
                    field.helperText :
                    valueError}
            </FormHelperText>
        </FormControl>
    );
};

const NewDeploymentModal = ({
    open,
    onClose,
}) => {
    const [deployment, setDeployment] = useState(INITIAL_DEPLOYMENT);
    const [fieldErrors, setFieldErrors] = useState({});

    const [postNotification] = useContext(NotificationContext);
    const {getAccessTokenSilently} = useAuth0();

    const handleFieldChange = (key, newValue) => {
        setDeployment((oldValue) => ({
            ...oldValue,
            [key]: newValue,
        }));
    };

    const handleFieldErrorChange = (key, error) => {
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [key]: error,
        }));
    };

    const handleSubmit = async () => {
        const controller = new AbortController();
        try {
            const authToken = await getAccessTokenSilently();
            await addNewDeployment({
                abortController: controller,
                authToken,
                data: deployment,
            }, {
                notifier: postNotification,
                onDone: () => {
                    window.location.reload();
                },
            });
        } catch (e) {
            postNotification("error", e.name, e.message);
            console.error(e);
        }
    };

    const isAnyFieldError = Object.values(fieldErrors).some((error) => null !== error);

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <ModalDialog
                color={"primary"}
                size={"lg"}
                sx={{minWidth: "600px"}}
                variant={"outlined"}
            >
                <ModalClose/>
                <DialogTitle>
                    {"Creat New Deployment"}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2}>
                        {DEPLOYMENT_SCHEMA.map((f) => (
                            <InputElement
                                field={f}
                                key={f.key}
                                onFieldChange={handleFieldChange}
                                onFieldErrorChange={handleFieldErrorChange}/>
                        ))}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isAnyFieldError}
                        onClick={handleSubmit}
                    >
                        {"Create"}
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    );
};

export default NewDeploymentModal;
