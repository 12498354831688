import {
    useEffect, useState,
} from "react";
import {
    useLocation, useNavigate,
} from "react-router-dom";

import {
    CssBaseline, CssVarsProvider,
} from "@mui/joy";

import Sidebar from "./ui/components/Sidebar/Sidebar";
import PageContainer from "./ui/pages/PageContainer";

import "@fontsource/inter";


const DEFAULT_TAB_NAME = "home";

const App = () => {
    const location = useLocation();

    const [tab, setTab] = useState(DEFAULT_TAB_NAME);
    const navigate = useNavigate();

    useEffect(() => {
        const [, tabNameFromUrl] = location.pathname.split("/");
        if ("" !== tabNameFromUrl) {
            setTab(tabNameFromUrl);
        }
    }, [location]);

    const handleTabChange = (newTab) => {
        navigate(`/${newTab}`);
    };

    return (
        <CssVarsProvider>
            <CssBaseline/>
            <Sidebar
                tab={tab}
                onTabChange={handleTabChange}/>
            <PageContainer/>
        </CssVarsProvider>
    );
};

export default App;
