import {
    useContext, useEffect, useState,
} from "react";

import {useAuth0} from "@auth0/auth0-react";
import Box from "@mui/joy/Box";
import Skeleton from "@mui/joy/Skeleton";
import Typography from "@mui/joy/Typography";

import {getAndSetDeployments} from "../../../api/deployment";
import {getAndSetUserProfile} from "../../../api/user";
import {NotificationContext} from "../../components/Notification/NotificationContext";
import {
    DeploymentCard, LoadingDeploymentCard, NewDeploymentCard,
} from "./DeploymentCard";
import NewDeploymentModal from "./NewDeploymentPopup";


const Dashboard = () => {
    const {
        isAuthenticated,
        isLoading,
        getAccessTokenSilently,
    } = useAuth0();

    const [postNotification] = useContext(NotificationContext);
    const [userProfile, setUserProfile] = useState(null);
    const [deployments, setDeployments] = useState(null);
    const [isCreatingDeployment, setIsCreatingDeployment] = useState(false);


    useEffect(() => {
        let intervalId = null;
        const controller = new AbortController();

        const fetchData = async () => {
            try {
                if (true === isLoading) {
                    return;
                }

                if (false === isAuthenticated) {
                    window.location.href = window.location.origin;

                    return;
                }

                const authToken = await getAccessTokenSilently();
                await getAndSetUserProfile(
                    {abortController: controller, authToken},
                    {notifier: postNotification, setter: setUserProfile}
                );

                const fetchAndSetDeployments = async () => {
                    await getAndSetDeployments(
                        {abortController: controller, authToken},
                        {notifier: postNotification, setter: setDeployments}
                    );
                };

                await fetchAndSetDeployments();
                intervalId = setInterval(fetchAndSetDeployments, 3000);
            } catch (e) {
                postNotification("error", e.name, e.message);
                console.error(e);
            }
        };

        fetchData().then();

        return () => {
            controller.abort();
            if (null !== intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [
        isLoading,
        isAuthenticated,
        postNotification,
        getAccessTokenSilently,
    ]);

    const handleNewDeploymentClick = () => {
        setIsCreatingDeployment(true);
    };

    return (
        <>
            <Typography
                level={"h1"}
                sx={{display: "flex"}}
            >
                {"Howdy,"}
                &nbsp;
                <Skeleton
                    loading={null === userProfile}
                    sx={{position: "relative"}}
                    width={190}
                >
                    {null === userProfile ?
                        "dummy" :
                        userProfile.firstName}
                </Skeleton>
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 3,
                    marginTop: "1.5rem",
                }}
            >
                <NewDeploymentCard
                    disabled={null === deployments}
                    onClick={handleNewDeploymentClick}/>
                {null === deployments ?
                    <LoadingDeploymentCard/> :
                    deployments.map((d, i) => (
                        <DeploymentCard
                            deploymentId={d._id}
                            deploymentName={d.deployment_name}
                            error={d.error}
                            key={i}
                            publicUrl={`http://${d.public_url}`}
                            region={d.region}
                            status={d.status}/>
                    ))}
            </Box>
            <NewDeploymentModal
                open={isCreatingDeployment}
                onClose={() => {
                    setIsCreatingDeployment(false);
                }}/>
        </>
    );
};

export default Dashboard;
